/**
 * Scroll to anchor on page load and on click
 */
/**
 * Scroll to anchor on page load and on click
 */
export class ScrollToAnchor {

	offset = 0;

	/**
	 * Constructor
	 *
	 * @param {number} offset
	 */
	constructor( offset ) {

		this.offset = offset;

		this.scrollToAnchorOnClick();
		this.scrollToAnchorOnPageLoad();

	}

	/**
	 * Scroll to anchor on click
	 */
	scrollToAnchorOnClick() {

		const mainElement = document.querySelector( 'header' );
		if ( mainElement ) {

			mainElement.querySelectorAll( 'a' ).forEach( ( anchor ) => {

				const href = anchor.getAttribute( 'href' );
				if ( href && href.includes( '#' ) ) {

					anchor.addEventListener( 'click', ( e ) => {

						e.preventDefault();

						const url = new URL( href );
						const target = document.querySelector( url.hash );
						if ( target ) {

							const headers = document.querySelectorAll( '.js-navigation' );
							headers.forEach( header => {

								header.classList.remove( 'js-open' );
								document.body.classList.remove( 'freeze' );

							} );
							window.scrollTo( {
								top: target.getBoundingClientRect().top + window.pageYOffset - this.offset,
								behavior: 'smooth',
							} );

						}

					} );

				}

			} );

		}

	}

	/**
	 * Scroll to anchor on page load
	 */
	scrollToAnchorOnPageLoad() {

		window.addEventListener( 'load', () => {

			const hash = window.location.hash;
			if ( hash ) {

				const target = document.querySelector( `main ${ hash }` );
				if ( target ) {

					window.scrollTo( {
						top: target.getBoundingClientRect().top + window.pageYOffset - this.offset,
						behavior: 'smooth',
					} );

				}

			}

		} );

	}

}
