/**
 * GotoNext section
 */
export class GotoNext {

	/**
	 * Constructor
	 *
	 * @return {void}
	 */
	constructor() {

		this.buttons = document.querySelectorAll( '.js-goto-next' );

	}

	/**
	 * Init
	 *
	 * @return {void}
	 */
	init() {

		this.buttons.forEach( button => {

			button.addEventListener( 'click', ( e ) => {

				e.preventDefault();
				let currentSection = e.target.closest( 'section' );

				if ( !currentSection ) {

					currentSection = e.target.closest( 'header' );

				}

				const nextSection = currentSection.nextElementSibling;
				if ( nextSection ) {

					window.scrollTo( {
						top: nextSection.offsetTop - 30,
						behavior: 'smooth'
					} );

				}


			} );

		} );

	}

}
