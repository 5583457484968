/* global google */

/**
 * Map class
 */
export class Map {

	/**
	 * Constructor
	 * @param {string} mapId
	 */
	constructor( mapId ) {

		this.mapId = mapId;

	}

	/**
	 * Init map
	 */
	initMap() {

		if ( typeof google === 'undefined' ) {

			// eslint-disable-next-line no-console
			console.error( 'Google Maps JavaScript API is not loaded' );
			return;

		}

		const mapPlace = document.getElementById( this.mapId );
		if ( !mapPlace ) {

			// eslint-disable-next-line no-console
			console.error( 'Map place is not found' );
			return;

		}
		const lat  = parseFloat( mapPlace.getAttribute( 'data-lat' ) );
		const lng = parseFloat( mapPlace.getAttribute( 'data-lng' ) );

		const companyLocation = { lat: lat, lng: lng };
		// eslint-disable-next-line no-console
		console.log( companyLocation );
		// eslint-disable-next-line no-unused-vars
		const map = new google.maps.Map( mapPlace, {
			zoom: 15,
			streetViewControl: false,
			mapTypeControl: false,
			zoomControl: true,
			fullscreenControl: false,
			center: companyLocation,
			styles: [
				{
					featureType: "all",
					stylers: [
						{ saturation: -100 },
						{ lightness: 50 }
					]
				}
			]
		} );
		// eslint-disable-next-line no-unused-vars
		const marker = new google.maps.Marker( {
			position: companyLocation,
			map: map
		} );

	}

}
