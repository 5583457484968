/**
 * Header Class
 */
export  class  Header{

	/**
	 * Constructor
	 *
	 * @param {string} containerEl
	 * @return {void}
	 */
	constructor( containerEl ){

		this.headers = document.querySelectorAll( containerEl );
		if( !this.headers ){

			return false;

		}
		this.mobileMenuOpen( this.headers );

	}

	/**
	 * Mobile Menu Open
	 *
	 * @param {NodeList} headers
	 * @return {void}
	 */
	mobileMenuOpen( headers ){

		this.mobileMenuOpen = document.querySelector( '.js-mobile-button' );
		this.mobileMenuClose = document.querySelector( '.js-mobile-button-close' );

		headers.forEach( header => {

			this.mobileMenuOpen.addEventListener( 'click', ( e ) => {

				header.classList.toggle( 'js-open' );
				document.body.classList.toggle( 'freeze' );
				e.preventDefault();

			} );
			this.mobileMenuClose.addEventListener( 'click', ( e ) => {

				header.classList.remove( 'js-open' );
				document.body.classList.remove( 'freeze' );
				e.preventDefault();

			} );

		} );

	}

}

