/*
 * General custom scripts
 */

/**
 * Top header - mobile menu Button
 */
import { Header } from "./front/Header";
new Header( ".js-navigation" );

/**
 * Google maps Map
 */
import { Map } from './front/Map';
import { GotoNext } from "./front/components/goto-next";

window.initMap = function initMap() {

	const map = new Map( 'map' );
	map.initMap();

	const gotoNext = new GotoNext();
	gotoNext.init();

}

/**
 * Scroll up b
 *
 */
import { ScrollUp } from './front/ScrollUp';
new ScrollUp( );

/**
 * Scroll to Anchor
 */
import { ScrollToAnchor } from './front/ScrollToAnchor';
new ScrollToAnchor( 150 );
