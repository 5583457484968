/**
 * Scroll up
 */
export  class ScrollUp {

	/**
	 * Constructor
	 */
	constructor() {

		this.scrollUp();

	}

	/**
	 * Scroll up
	 */
	scrollUp() {

		const scrollUpButton = document.querySelector( '.js-go-up' );
		if ( scrollUpButton ) {

			scrollUpButton.addEventListener( 'click', () => {

				window.scrollTo( {
					top: 0,
					behavior: 'smooth',
				} );

			} );

		}

	}

}
